<!-- Navbar -->
<mat-toolbar class="toolbar">
    <img class="logo" alt="Chris Danz Logo" src="../assets/logo.png" (click)="display('home')" />
    <span class="spacer"></span>
    <div class="navbar" fxShow="true" fxHide.lt-sm="true">
        <span [ngClass]="displayState == 'home' ? 'toolbar-title-selected' : 'toolbar-title'" (click)="display('home')">Home</span>
        <span [ngClass]="displayState == 'about' ? 'toolbar-title-selected' : 'toolbar-title'" (click)="display('about')">About</span>
        <span [ngClass]="displayState == 'lessons' ? 'toolbar-title-selected' : 'toolbar-title'" (click)="display('lessons')">Lessons</span>
        <span [ngClass]="displayState == 'arrangements' ? 'toolbar-title-selected' : 'toolbar-title'" (click)="display('arrangements')">Arrangements</span>
        <span [ngClass]="displayState == 'contact' ? 'toolbar-title-selected' : 'toolbar-title'" (click)="display('contact')">Contact</span>
    </div>

    <div fxShow="true" fxHide.gt-xs="true">
        <button class="navbar-button" mat-icon-button (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</mat-toolbar>

<!-- Main Content -->
<mat-sidenav-container style="background-color: rgba(0,0,0,0)">
    <!-- Sidenav -->
    <mat-sidenav class="side-navbar" #sidenav position="end">
        <mat-nav-list>
            <a mat-list-item (click)="display( 'home'); sidenav.toggle(); " class="sidenav-button">Home</a>
            <a mat-list-item (click)="display( 'about'); sidenav.toggle(); " class="sidenav-button">About</a>
            <a mat-list-item (click)="display( 'lessons'); sidenav.toggle(); " class="sidenav-button">Lessons</a>
            <a mat-list-item (click)="display( 'arrangements'); sidenav.toggle(); " class="sidenav-button">Arrangements</a>
            <a mat-list-item (click)="display( 'contact'); sidenav.toggle(); " class="sidenav-button">Contact</a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div style="height: 88vh;">

            <!-- Home -->
            <div *ngIf="displayState=='home' " class="content " role="main ">
                <mat-card class="card ">
                    <div class="card-body ml-lg-5 mr-lg-5 ">
                        <h3 class="card-title ">Welcome</h3>
                        <hr class="divider ">
                        <br><br>
                        <div class="row ">
                            <div class="col-sm-8 card-text pr-lg-5 ">Hi, my name is Christopher Danz. I currently maintain an active teaching studio in Salt Lake City, Utah with a wide range of ages and a mix of in person and virtual students. I received a Master of Music Degree from the Frost
                                School of Music in Miami, Florida, studying with Professor Craig Morris and a Bachelor of Music Degree from the Eastman School of Music, studying with Professor James Thompson.
                                <br><br><br>An avid chamber musician as well as an accomplished soloist, I have been a semi-finalist in both the Solo and the Ensemble Divisions of the National Trumpet Competition. I have performed with Orchestras, Wind
                                Ensembles and Brass Quintets in the Miami and New York areas. In addition to my performance activities, I am passionate about arranging music for various ensembles and teaching aspiring musicians of all ages.
                                <br><img class="logo-2" alt="Chris Danz Logo" src="../assets/logo.png" (click)="display('home')" />
                            </div>
                            <div class="col-sm-4 pl-lg-5 "><img class="headshot " src="../assets/homepage_bio.jpg ">
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- About -->
            <div *ngIf="displayState=='about' " class="content " role="main ">
                <mat-card class="card ">
                    <div class="card-body ml-lg-5 mr-lg-5 ">
                        <h3 class="card-title ">About</h3>
                        <hr class="divider ">
                        <br><br>
                        <div class="row ">
                            <div class="col-sm-8 card-text pr-lg-5 ">
                                Christopher Danz began playing trumpet at the age of 9. Coming from a musical family, he previously played violin beginning at age 6 but decided that trumpet is where his true passion lies.<br><br><br> Growing up in a small
                                town near Frankfurt, Germany, Christopher played in community bands and youth orchestras until 2011, when Christopher and his family moved to Naples, Florida. In Naples he joined his high school marching band, jazz band,
                                and the local youth orchestra and studied with David Dash. During this time Christopher also attended the Bower School of Music at Florida Gulf Coast University where he studied with Matthew Sonneborn as part of a dual
                                enrollment program.
                                <br><br><br> In 2014 Christopher began his Bachelor’s Degree studies at the <a href="https://www.esm.rochester.edu/ " target="_blank " class="link ">Eastman School
                                    of Music</a>, studying with James Thompson. During his 4 years at Eastman, Christopher performed with the Philharmonia, Symphony Orchestra, Wind Ensemble, Wind Orchestra, a brass quintet, a trumpet quartet, and a large
                                brass ensemble. As a soloist, he was selected as a semi-finalist for the <a href="http://www.nationaltrumpetcomp.org/ " target="_blank " class="link ">National Trumpet Competition</a> and performed two solo recitals in
                                Eastman’s famed concert halls. Christopher also enjoyed freelancing with various orchestras and chamber ensembles in the Rochester area. In addition to his performance activities, Christopher enjoyed taking courses in arranging,
                                conducting, and linguistics.
                                <br><br><br> Christopher earned his Bachelor of Music degree in Trumpet Performance from Eastman in 2018 and spent the following year in New York City freelancing, taking private lessons, and immersing himself in the world
                                class musical and cultural scene.
                                <br><br><br>In 2021 Christopher completed a Master of Music degree at the <a href="https://www.frost.miami.edu/ " target="_blank " class="link ">Frost School of
                                    Music</a> at the University of Miami studying with Craig Morris. In addition to playing in the Frost Symphony Orchestra, Wind Ensemble, and a brass quintet, Christopher was a substitute with New World Symphony and freelanced
                                in the Miami area.<br><br><br> Christopher is also a talented and passionate arranger having arranged music for various small ensembles from different genres. Most notably he arranged the Overture to the Barber of Seville
                                for his trumpet quartet to participate in the National Trumpet Competition. Christopher enjoys working with aspiring musicians of all ages, not only teaching trumpet, but giving lessons in music theory and arranging as
                                well. During his free time, Christopher enjoys playing board games with his wife Jessica and trying to keep up with their puppy Elinor.
                            </div>
                            <div class="col-sm-4 pl-lg-5 "><img class="headshot " src="../assets/about_bio.jpg "></div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Lessons -->
            <div *ngIf="displayState=='lessons' " class="content " role="main ">
                <mat-card class="card ">
                    <div class="card-body ml-lg-5 mr-lg-5 ">
                        <h3 class="card-title ">Lessons</h3>
                        <hr class="divider ">
                        <br><br>
                        <p class="card-text ">
                            Private lessons are a quick way to get better at trumpet and to get individualized help with the things you want to improve!<br><br><br> I will meet you at your level during these one-on-one lessons, whether you are playing
                            trumpet for the first time, or you have already been playing trumpet for years. During the lessons we will not only discuss the techniques to get the best sound, but we will also be developing other aspects of being a well-rounded
                            musician, such as musicality and general musicianship. During the lessons, we will be working on pieces that are appropriate to your experience level, and that will challenge you both technically and musically and I will provide
                            exercises and etudes to supplement the pieces we are working on. You are also encouraged to bring in any pieces you might currently be working on in a band or orchestra, if you would like to get some feedback on those. I will
                            provide positive feedback to reinforce good trumpet habits and to let you know what you are already doing well, and what you can do to improve your trumpet playing. No prior musical experience or knowledge is necessary, but
                            if you have already been playing trumpet it would be helpful to bring in the latest song or piece that you have been working on.<br><br><br> In person lessons are held at my home in Bountiful, Utah, or I can travel to you for
                            an additional cost. Virtual lessons are held via Zoom, and I use a professional microphone and audio interface. I’m also knowledgeable about the best audio settings for students to use to help you present your best sound.
                            <br><br><br>
                        </p>
                        <b>Pricing</b><br><br> 30 minute lesson: $25<br> 1 hour lesson: $50<br><br>
                        <p>If you would like to schedule a lesson or are interested in learning more about my teaching philosophy please <a class="link " (click)="display( 'contact') ">send me a message</a>.
                            <br><br><br></p>
                        <b>Reviews</b><br><br>
                        <p class="card-text "><i>
                                My son was very engaged and remarked "I like how he teaches". During remote school, our
                                school band teacher hasn't been able to engage as well as in person. This was the
                                perfect supplement, if not a better choice altogether. The improvement from Monday
                                to Friday's lesson was incredible. Chris also helped to troubleshoot a stuck trumpet
                                valve.<br><br> Mr. Chris is a very patient instructor! My son improved so much in two
                                weeks and always looked
                                forward to his trumpet lessons.<br><br> My 9 year old son had a wonderful time! He
                                learned so much! He didn't have any experience 5 days ago and now he is so proud of
                                himself because he can play the trumpet!! The teacher
                                was so kind, patient, and informative!! It is truly amazing that he learned so much in
                                such a short amount of time!</i>
                        </p>
                    </div>
                </mat-card>
            </div>

            <!-- Arrangements -->
            <div *ngIf="displayState=='arrangements' " class="content " role="main ">
                <mat-card class="card ">
                    <div class="card-body ml-lg-5 mr-lg-5 ">
                        <h3 class="card-title ">Arrangements</h3>
                        <hr class="divider ">
                        <br><br>
                        <p class="card-text ">
                            Thank you for your interest in my arrangements, below are some samples of my previous work. If there is a piece that you would like to have arranged, please reach out to me with the title of the piece and the instrumentation.
                            <br><br>Rates are negotiable and based on length of piece and number of instruments.
                        </p>
                        <a class="link " (click)="display( 'contact') ">Send me a message to find out more.</a>
                        <div class="row arrangements ">
                            <div class="col-md-6 ">
                                <b>Clair de Lune</b><br><br>
                                <audio class="audio " controls controlsList="nodownload ">
                                    <source src="../assets/Clair de Lune audio.mp3 " type="audio/ogg ">
                                    Your browser does not support the audio element.
                                </audio>
                                <iframe src="../assets/Clair_de_Lune_Sample.pdf " width="100% " height="600px "></iframe>
                            </div>
                            <div class="col-md-6 ">
                                <b>The Barber of Seville</b><br><br>
                                <audio class="audio " controls controlsList="nodownload ">
                                    <source src="../assets/Barber of Seville Trumpet Quartet audio.mp3 "
                                        type="audio/ogg ">
                                    Your browser does not support the audio element.
                                </audio>
                                <iframe src="../assets/Barber_of_Seville_Sample.pdf " width="100% " height="600px "></iframe>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Contact -->
            <div *ngIf="displayState=='contact'" class="content" role="main">
                <mat-card class="card">
                    <div class="card-body ml-lg-5 mr-lg-5">
                        <h3 class="card-title">Contact</h3>
                        <hr class="divider">
                        <br><br>
                        <p class="card-text"> Whether you need a trumpet player for your next gig, have a question regarding arrangements, want to start learning trumpet, or just want to chat about music in general, I am looking forward to hearing from you.</p>
                        <form [formGroup]="contactForm" novalidate class="contact-form" (ngSubmit)="onSubmit() ">
                            <div class="form-group">
                                <input class="checkbox" type="checkbox" value="false" formControlName="lessons" (click)="toggleLessonsForm()">I am interested in private lessons!
                            </div>
                            <div class="form-group">
                                <label class="form-label">First Name</label>
                                <div class="required-field ">(required)</div>
                                <input [ngClass]="contactForm.controls['firstname'].invalid && (contactForm.controls['firstname'].dirty || contactForm.controls['firstname'].touched) ? 'form-control form-value-required' : 'form-control'" formControlName="firstname" type="text" placeholder="First Name">
                            </div>
                            <div class="form-group">
                                <label class="form-label">Last Name</label>
                                <input class="form-control" formControlName="lastname" type="text" placeholder="Last Name">
                            </div>
                            <div class="form-group">
                                <label class="form-label">Email</label>
                                <div class="required-field ">(required)</div>
                                <input [ngClass]="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched) ? 'form-control form-value-required' : 'form-control'" formControlName="email" type="text" placeholder="Email Address">
                            </div>
                            <div class="form-group">
                                <label class="form-label">Message</label>
                                <div class="required-field ">(required)</div>
                                <textarea [ngClass]="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched) ? 'form-control form-value-required' : 'form-control'" formControlName="message" placeholder="Write something ..."></textarea>
                            </div>
                            <div id="lessonsForm" class="lessons-form">
                                <div class="form-group">
                                    <label class="form-label">Lesson Location</label><br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="lessonLocation" id="radioInPerson" value="in-person">
                                        <label class="form-check-label" for="radioInPerson">In-Person</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" formControlName="lessonLocation" id="radioVirtual" value="virtual">
                                        <label class="form-check-label" for="radioVirtual">Virtual</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Preferred Lesson Day</label><br>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDayMonday" value="false" id="checkMonday">
                                        <label class="form-check-label" for="checkMonday">Monday</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDayTuesday" value="false" id="checkTuesday">
                                        <label class="form-check-label" for="checkTuesday">Tuesday</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDayWednesday" value="false" id="checkWednesday">
                                        <label class="form-check-label" for="checkWednesday">Wednesday</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDayThursday" value="false" id="checkThursday">
                                        <label class="form-check-label" for="checkThursday">Thursday</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDayFriday" value="false" id="checkFriday">
                                        <label class="form-check-label" for="checkFriday">Friday</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDaySaturday" value="false" id="checkSaturday">
                                        <label class="form-check-label" for="checkSaturday">Saturday</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" formControlName="lessonDaySunday" value="false" id="checkSunday">
                                        <label class="form-check-label" for="checkSunday">Sunday</label>
                                    </div>
                                </div>
                            </div><br>

                            <button class="submit-button" type="submit" [disabled]="contactForm.pristine || contactForm.invalid">
                                Submit
                            </button>
                        </form>
                    </div>
                </mat-card>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- Footer -->
<footer class="footer">&copy; 2021 Christopher Danz</footer>