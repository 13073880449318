import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'portfolio';
  displayState = 'home';

  contactForm = new FormGroup({
    'firstname': new FormControl('', Validators.required),
    'lastname': new FormControl(''),
    'email': new FormControl('', [Validators.email, Validators.required]),
    'message': new FormControl('', Validators.required),
    'lessons': new FormControl(false),
    'lessonLocation': new FormControl(''),
    'lessonDayMonday': new FormControl(false),
    'lessonDayTuesday': new FormControl(false),
    'lessonDayWednesday': new FormControl(false),
    'lessonDayThursday': new FormControl(false),
    'lessonDayFriday': new FormControl(false),
    'lessonDaySaturday': new FormControl(false),
    'lessonDaySunday': new FormControl(false)
  });

  constructor(public dialog: MatDialog, private http: HttpClient) { }

  display(state) {
    this.displayState = state;
    this.contactForm = new FormGroup({
      'firstname': new FormControl('', Validators.required),
      'lastname': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.required]),
      'message': new FormControl('', Validators.required),
      'lessons': new FormControl(false),
      'lessonLocation': new FormControl(''),
      'lessonDayMonday': new FormControl(false),
      'lessonDayTuesday': new FormControl(false),
      'lessonDayWednesday': new FormControl(false),
      'lessonDayThursday': new FormControl(false),
      'lessonDayFriday': new FormControl(false),
      'lessonDaySaturday': new FormControl(false),
      'lessonDaySunday': new FormControl(false)
    });
  }

  toggleLessonsForm() {
    var lessonsForm = document.getElementById("lessonsForm");

    if (lessonsForm.style.display == "block") {
      lessonsForm.style.display = "none";
    } else {
      lessonsForm.style.display = "block";
    }
  }

  onSubmit() {
    const url = "https://chrisdanz.com/contactmail.php?firstname=" + this.contactForm.value.firstname + "&lastname=" + this.contactForm.value.lastname + "&email=" + this.contactForm.value.email + "&message=" + this.contactForm.value.message + "&lessons=" + this.contactForm.value.lessons + "&lessonLocation=" + this.contactForm.value.lessonLocation + "&lessonDayMonday=" + this.contactForm.value.lessonDayMonday + "&lessonDayTuesday=" + this.contactForm.value.lessonDayTuesday + "&lessonDayWednesday=" + this.contactForm.value.lessonDayWednesday + "&lessonDayThursday=" + this.contactForm.value.lessonDayThursday + "&lessonDayFriday=" + this.contactForm.value.lessonDayFriday + "&lessonDaySaturday=" + this.contactForm.value.lessonDaySaturday + "&lessonDaySunday=" + this.contactForm.value.lessonDaySunday;
    this.http.get<any>(url).subscribe();
    this.openContactConfirmation(this.contactForm.value.firstname);
    this.contactForm = new FormGroup({
      'firstname': new FormControl('', Validators.required),
      'lastname': new FormControl(''),
      'email': new FormControl('', [Validators.email, Validators.required]),
      'message': new FormControl('', Validators.required),
      'lessons': new FormControl(false),
      'lessonLocation': new FormControl(''),
      'lessonDayMonday': new FormControl(false),
      'lessonDayTuesday': new FormControl(false),
      'lessonDayWednesday': new FormControl(false),
      'lessonDayThursday': new FormControl(false),
      'lessonDayFriday': new FormControl(false),
      'lessonDaySaturday': new FormControl(false),
      'lessonDaySunday': new FormControl(false)
    });
    var lessonsForm = document.getElementById("lessonsForm");
    lessonsForm.style.display = "none";
  }

  openContactConfirmation(firstname: string): void {
    const dialogRef = this.dialog.open(EmailConfirmationDialog, {
      autoFocus: false,
      data: { name: firstname }
    });
  }
}


@Component({
  selector: 'email-confirmation-dialog',
  templateUrl: 'email-confirmation-dialog.html',
  styleUrls: ['./app.component.css']
})
export class EmailConfirmationDialog {

  constructor(
    public dialogRef: MatDialogRef<EmailConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  close(): void {
    this.dialogRef.close();
  }

}
